<template>
  <v-card fluid flat tile min-height="92vh" class="back mb-n15 pa-6 px-md-15">
    <v-row>
      <v-col cols md="7">
        <v-card class="fill-height">
          <v-card-title>Form Builder</v-card-title>
          <v-divider class="mx-3"></v-divider>
          <v-card-text>
            <v-autocomplete
              v-model="selectedList"
              class="pt-1"
              :items="lists"
              item-text="name"
              item-value="id"
              label="Lists"
              autocomplete="false"
              prepend-inner-icon="view_list"
              hide-details
              dense
              background-color="inverted"
              outlined
              @change="generateHtml()"
              flat
            />
          </v-card-text>
          <v-card-text>
            <label>Form Title</label>
            <v-text-field
              v-model="form.title"
              @change="generateHtml()"
              hide-details
              class="mb-2"
              filled
              dense
            />
            <label>Form Sub Title</label>
            <v-text-field
              v-model="form.subTitle"
              @change="generateHtml()"
              hide-details
              class="mb-2"
              filled
              dense
            />
            <label>Fields in Form</label>
            <v-row class="mt-1 ml-n4">
              <v-col
                cols
                md="6"
                v-for="(item, i) in fields"
                :key="i"
                class="my-0 py-0"
              >
                <v-checkbox
                  :value="form.fields.includes(item)"
                  :label="item.name"
                  dense
                  @change="updateFieldList(item)"
                />
              </v-col>
            </v-row>
            <v-checkbox
              v-model="form.legal"
              @change="generateHtml()"
              label="Legal Text"
              class="ml-n1 font-weight-medium"
            ></v-checkbox>
            <v-textarea
              v-model="form.legalText"
              @change="generateHtml()"
              :disabled="!form.legal"
              auto-grow
              rows="2"
              filled
              dense
            />
            <label>Button Text</label>
            <v-text-field
              @change="generateHtml()"
              v-model="form.buttonText"
              filled
              dense
            />
            <label>Form Width</label>
            <v-text-field
              @change="generateHtml()"
              v-model="form.width"
              filled
              dense
              suffix="px"
            />
            <label>Redirect URL *</label>
            <v-text-field
              v-model="form.redirect"
              hide-details
              class="mb-2"
              filled
              dense
            />
            <label>Color </label>
            <div class="d-flex mb-5">
              <v-avatar
                v-for="color in colors"
                :key="color"
                @click="
                  form.color = color;
                  generateHtml();
                "
                :color="color"
                class="mx-1"
                size="25"
              />
            </div>
            <label>Theme</label>
            <v-radio-group v-model="form.theme" @change="generateHtml()" row>
              <v-radio label="Light" :value="theme[0]"></v-radio>
              <v-radio label="Dark" :value="theme[1]"></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols md="5">
        <v-card class="fill-height">
          <v-card-title>Form Preview</v-card-title>
          <v-divider class="mx-3"></v-divider>
          <v-card-text>
            <v-card :dark="form.theme == 'dark'">
              <v-card-text>
                <h2>{{ form.title }}</h2>
                <span> {{ form.subTitle }}</span>
                <v-divider :color="form.color" class="pt-1 mt-1"></v-divider>
                <div v-for="(field, i) in form.fields" :key="i">
                  <v-text-field
                    disabled
                    :label="field.name"
                    :placeholder="`Enter your ${field.name}`"
                    hide-details
                    class="my-4"
                    outlined
                    dense
                  />
                </div>
                <span v-if="form.legal">
                  <v-checkbox
                    :color="form.color"
                    :label="form.legalText"
                  ></v-checkbox>
                </span>
                <div class="d-flex justify-end mt-2">
                  <v-btn :color="form.color" dark>{{ form.buttonText }}</v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols>
        <v-card class="mb-3">
          <v-card-title>
            HTML Code
            <v-spacer></v-spacer>
            <v-btn depressed @click="copy(htmlCode)">
              <v-icon left>menu</v-icon>
              Copy
            </v-btn>
          </v-card-title>
          <v-card-text class="inverted rounded">
            <code class="pa-3 transparent">
              {{ htmlCode }}
            </code>
          </v-card-text>
        </v-card>
        <v-card class="mb-3">
          <v-card-title>
            CSS Code
            <v-spacer></v-spacer>
            <v-btn depressed @click="copy(cssCode)">
              <v-icon left>menu</v-icon>
              Copy
            </v-btn>
          </v-card-title>
          <v-card-text class="inverted rounded">
            <code class="pa-3 transparent">
              {{ cssCode }}
            </code>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    tab: "",
    lists: [],
    selectedList: "",
    fields: [],
    selectedFields: [],
    colors: [
      "red",
      "pink",
      "purple",
      "indigo",
      "blue",
      "green",
      "yellow",
      "amber",
      "brown",
      "black",
    ],
    theme: ["light", "dark"],
    form: {
      title: "Untitled",
      subTitle: "Subtitle",
      fields: [],
      legal: false,
      legalText:
        "By clicking I am aware of the terms and condition and privacy policy",
      buttonText: "Submit",
      width: "500",
      redirect: "https://godial.cc",
      color: "black",
      theme: "light",
    },
    htmlCode: "",
    cssCode: "",
    token: "",
  }),
  computed: {
    ...mapGetters(["ENDPOINT", "fieldList", "teams", "selectedTeam"]),
  },
  created() {
    this.fetchLists();
    this.getAllFields();
    this.getAccessToken();
  },
  watch: {
    async selectedTeam() {
      try {
        this.selectedList = "";
        this.getAllFields();
        await this.fetchLists(); // fetch list
        this.refresh();
      } catch (e) {
        console.log(e);
      }
    },
  },
  methods: {
    getAccessToken() {
      const self = this;
      var aToken = self.$store.getters.user.token;
      self.$http
        .get(`${this.ENDPOINT}/externals/token?access_token=${aToken}`)
        .then((response) => {
          self.token = response.body.id;
          self.generateHtml();
        })
        .catch((err) => {
          self.$swal({ type: "error", text: err.body.error.message });
        });
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];
        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;
        if (this.lists.length < 1) {
          return;
        }
        this.selectedList = this.lists[0].id;
      } catch (e) {
        console.log("error in list fetch", e);
      }
    },
    getAllFields() {
      // Set Custom Field in
      var tempFields = [
        { name: "Name", value: "name" },
        { name: "Company Name", value: "companyName" },
        { name: "Email", value: "email" },
        { name: "Phone", value: "phone" },
        { name: "Secondary Phone", value: "secondPhone" },
        { name: "Extra", value: "extra" },
        { name: "Remarks", value: "remarks" },
        { name: "Note", value: "note" },
      ];

      // if (this.fieldList.length > 1) {
      //     var fieldType = "";
      //     this.fieldList.forEach((field) => {
      //     if (
      //         field.type == "text" ||
      //         field.type == "" ||
      //         field.type == "mcq" ||
      //         field.type == "checkbox"
      //     ) {
      //         fieldType = "customField";
      //     } else if (field.type == "number") {
      //         fieldType = "customFieldNumber";
      //     } else if (field.type == "date") {
      //         fieldType = "customFieldDate";
      //     }

      //     tempFields.push({
      //         name: field.name,
      //         value: field.name,
      //         fieldType: fieldType,
      //     });
      //     });
      // }
      this.fields = tempFields;
      // console.log("tempFields: ",this.fields);
    },
    async refresh() {},
    updateFieldList(item) {
      // console.log(this.form.fields.includes(item));
      if (!this.form.fields.includes(item)) this.form.fields.push(item);
      else
        this.form.fields = this.form.fields.filter((f) => {
          return f !== item;
        });
      // console.log(this.form.fields);
      this.generateHtml();
    },
    generateHtml() {
      var legalText = this.form.legalText;
      var fieldHTML = this.form.fields
        .map((field) => {
          return `<fieldset class="go-text-field"><input type="text" placeholder="${field.name}" name="${field.value}"></fieldset>`;
        })
        .join("");
      var basic = `<div class="go-flex">
                <form class="go-card go-theme" 
			    action="https://enterprise.godial.cc/meta/api/externals/contact/add?access_token=${
            this.token
          }" method="POST">
				<div class="go-card-title">
					<h2 class="go-title">${this.form.title}</h2>
					<span class="go-subtitle">${this.form.subTitle}</span>
				</div>
				<hr class="go-divider accent-color">
				<div class="go-card-text">
					<input type="hidden" name="listId" value="${this.selectedList}">
                    <input type="hidden" name="redirect" value="${
                      this.form.redirect
                    }">
                    ${fieldHTML}
                    ${
                      this.form.legal
                        ? `<fieldset class="go-legal">
						<input class="accent-color" type="checkbox" name="agreement">
						<div class="go-label">
							${legalText}
						</div>
				</fieldset>`
                        : ""
                    }
					
				</div>
				<div class="go-card-actions">
					<button class="accent-color" type="submit">${this.form.buttonText}
					</button>
				</div>
			</form>
            </div>`;
      this.htmlCode = basic;
      this.generateCss();
    },
    generateCss() {
      var colorMap = {
        red: "#F44336",
        pink: "#E91E63",
        purple: "#9C27B0",
        indigo: "#3F51B5",
        blue: "#03A9F4",
        green: "#8BC34A",
        yellow: "#FFEB3B",
        amber: "#FFC107",
        brown: "#795548",
        black: "#000",
      };
      var cssBasic = `<style>
.go-flex{
    display:flex;
    justify-content:center;
}
.go-theme{
    background:${this.form.theme == "dark" ? `#292929` : `#fff`};
    color:${this.form.theme == "dark" ? `#e6e6e6` : `#555555`};
}
.go-theme input{
    color:${this.form.theme == "dark" ? `#e6e6e6` : `#3f3f3f`};
}
.go-card{
    box-shadow: 1px 1px 15px #83838355;
    margin:12px;
    padding:12px 16px;
    border-radius: 4px;
    width: ${this.form.width == "" ? 345 : `${this.form.width}px`};
    font-family: Roboto, 'Segoe UI', system-ui, Oxygen,'Open Sans', sans-serif;
}
.go-title{
    margin:5px 0px;
}
.go-subtitle{
    color:#797979;
}
.go-card-text{
    padding:10px 0;
}
.go-text-field{
    border:1px solid #555555;
    border-radius:3px;
    margin-bottom:12px;
    height:20px;
}
.go-text-field input{
    background-color: transparent;
    width: 100%;
    font-size:18px;
    font-family: Roboto, 'Segoe UI', system-ui, Oxygen,'Open Sans', sans-serif;
    border:none;
    outline: none;
}
.go-legal{
    border:none;
    padding:0;
    display: flex;
}
.go-legal .go-label{
    margin-left:10px;
}
.go-legal .go-label a{
    font-size: 16px;
    font-weight:300;
    text-decoration: none;
    color:inherit;
}
.go-card-actions{
    display: flex;
    flex-direction: row-reverse;
}
.accent-color{
    background-color: ${colorMap[this.form.color]};
    accent-color:${colorMap[this.form.color]};
}
.go-divider{
    height:4px;
    border:none;
}
.go-legal input{
    height:15px;
    width:15px;
}
.go-card-actions button{
    border:none;
    border-radius: 2px;
    padding: 10px 20px;
    color:#fff;
    text-transform: uppercase;
    font-size: 15px;
    font-family: Roboto, 'Segoe UI', system-ui, Oxygen,'Open Sans', sans-serif;
    font-weight: 500;
}
</style>`;
      this.cssCode = cssBasic;
    },
    copy(text) {
      const self = this;
      this.$copyText(text).then(console.log("Copied"));
    },
  },
};
</script>

<style></style>
